<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">人员管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">山西学员列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <!-- <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input
                v-model="userName"
                type="text"
                size="small"
                placeholder="请输入姓名"
                clearable
              />
            </div>
            <div title="身份证号" class="searchboxItem ci-full">
              <span class="itemLabel">身份证号:</span>
              <el-input
                v-model="idCard"
                type="text"
                size="small"
                placeholder="请输入身份证号"
                clearable
              />
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input
                v-model="mobile"
                type="text"
                size="small"
                placeholder="请输入手机号"
                clearable
              />
            </div> -->
            <div
              title="学员搜索"
              style="display: flex; align-items: center"
              class="searchboxItem ci-full"
            >
              <span class="itemLabel">学员搜索:</span>
              <el-select
                v-model="userId"
                placeholder="请选择"
                no-data-text="没有数据"
                remote
                size="small"
                clearable
                :remote-method="superUserSelect"
                @visible-change="clearUserSearchModel"
              >
                <template>
                  <div class="select-header">
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 100px;
                      "
                      >学员姓名</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 140px;
                      "
                      >身份证号码</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 100px;
                      "
                      >学员电话</span
                    >
                  </div>
                  <div class="select-header">
                    <el-input
                      v-model="seaUserName"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="学员姓名"
                      clearable
                      style="width: 100px"
                    />
                    <el-input
                      v-model="seaUserIdcard"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="身份证号码"
                      clearable
                      style="margin-left: 50px; width: 140px"
                    />
                    <el-input
                      v-model="seaUserMobile"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="学员电话"
                      clearable
                      style="margin-left: 50px; width: 100px"
                    />
                  </div>
                </template>
                <el-option
                  v-for="item in userList"
                  :key="item.userId"
                  :label="item.userName"
                  :value="item.userId"
                >
                  <span style="width: 100px; font-size: 13px">{{
                    item.userName
                  }}</span>
                  <span
                    style="width: 140px; margin-left: 50px; font-size: 13px"
                    >{{ item.idcard }}</span
                  >
                  <span
                    style="width: 100px; margin-left: 50px; font-size: 13px"
                    >{{ item.mobile }}</span
                  >
                </el-option>
              </el-select>
            </div>
            <div title="学员状态" class="searchboxItem ci-full">
              <span class="itemLabel">学员状态:</span>
              <el-select
                v-model="StudentStatus"
                clearable
                placeholder="请选择学员状态"
                size="small"
              >
                <el-option
                  v-for="item in StudentStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
                <!-- <el-option  label="已认证" value="20"></el-option>
                <el-option label="未认证" value="10"></el-option>-->
              </el-select>
            </div>
            <div title="领劵时间" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 6rem">领劵时间:</span>
              <el-date-picker
                clearable
                style="width: 260px"
                size="small"
                format="yyyy-MM-dd"
                v-model="startTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div title="入班时间" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 6rem">入班时间:</span>
              <el-date-picker
                clearable
                style="width: 260px"
                size="small"
                format="yyyy-MM-dd"
                v-model="inTheClassTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
              />
              <el-table-column
                label="姓名"
                align="left"
                prop="userName"
                show-overflow-tooltip
                width="180"
              />
              <el-table-column
                label="身份证号"
                align="left"
                prop="idcard"
                show-overflow-tooltip
                width="180"
              />
              <el-table-column
                label="手机号"
                align="left"
                prop="mobile"
                show-overflow-tooltip
                width="180"
              />
              <el-table-column
                label="领劵时间"
                align="left"
                prop="shanxiUserStart"
                show-overflow-tooltip
                width="180"
              />
              <el-table-column
                label="状态"
                align="left"
                prop="shanxiUserState"
                show-overflow-tooltip
                width="180"
              >
                <template slot-scope="scope">
                  {{ scope.row.shanxiUserState == "10" ? "无资格" : "已入班" }}
                </template>
              </el-table-column>
               <el-table-column
                label="入班时间"
                align="createTime"
                prop="mobile"
                show-overflow-tooltip
                width="180"
              >
              <temaplate slot-scope="scope">
                {{scope.row.createTime | momentDate}}
              </temaplate>
               </el-table-column>
              <el-table-column
                label="说明"
                align="left"
                prop="msg"
                show-overflow-tooltip
              />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "classDaily",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      userId: "",
      seaUserName: "",
      seaUserIdcard: "",
      seaUserMobile: "",
      userList: [{}],
      StudentStatus: "",
      startTime:'',
inTheClassTime:'',
      StudentStatusList: [
        {
          value: "20",
          label: "已入班",
        },
        {
          value: "10",
          label: "无资格",
        },
      ],
    };
  },
  //页面初始化时不自动查询列表数据
  created() {
    this.superUserSelect();
  },
  computed: {},
  methods: {
    superUserSelect(e) {
      this.$post(
        "/biz/user/superUserSelect",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          userName: this.seaUserName,
          idcard: this.seaUserIdcard,
          mobile: this.seaUserMobile,
          projectId: this.projectId,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.userList = [{}];
        } else {
          this.userList = res.data.list;
        }
      });
    },
    clearUserSearchModel(e) {
      if (e) {
        this.seaUserName = "";
        this.seaUserIdcard = "";
        this.seaUserMobile = "";
        this.superUserSelect();
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.userId) {
        params.userId = this.userId;
      }
      if (this.StudentStatus) {
        params.state = this.StudentStatus;
      }
      if (this.startTime) {
        params.couponStartDate = this.startTime[0];
        params.couponEndDate = this.startTime[1];
      }
      if (this.inTheClassTime) {
        params.joinClassStartDate = this.inTheClassTime[0];
        params.joinClassEndDate = this.inTheClassTime[1];
      }
      this.doFetch({
        url: "/run/user/shanxi/user/list",
        params,
        pageNum,
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scope>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
